import React, {useEffect, useState} from 'react';
import moment from "moment";
import Button from '@material-ui/core/Button';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    FunctionField,
    BulkDeleteButton,
    SelectInput
} from 'react-admin';

import useAccessRole from '../../../hooks/useAccessRole';
import BankMovementsProvider from "../../../Providers/BankMovements";
// import useModal from "../../../hooks/useModal";
// import ModalExport from "../../../Components/ModalExport";
import {SOURCES} from "../../Transactions/Filters/TransactionFilter/utils";

const BankMovementsList = (props) => {
    const [updateDate, setUpdateDate] = useState([]);
    const {isAccess} = useAccessRole();
    // const { open, showModal, hiddeModal } = useModal();

    const lastUpdate = async () => {
        try {
            let response = await BankMovementsProvider.lastUpdate();
            setUpdateDate(response.data.refreshed_at.data);
        } catch {}
    }

    useEffect(() => {
        lastUpdate();
    }, [])

    const AssignedButton = (recordProps) => {
        const { record } = recordProps;
        const { history } = props;

        return record.hasOwnProperty('status') && record.status === 'unassigned' && record.transaction_type !== 'other' ? (
            <Button size="small" style={{ backgroundColor: '#0090ff' }} onClick={() => {
                history.push(`/bank_movements/${record.id}`);
            }}>
                ASIGNAR
            </Button>
        ) : null;
    };

    return (
        <div>
            <div
                style={{
                    color: "white",
                    position: "absolute",
                    zIndex: "1",
                    width: '50%',
                    marginTop: -17,
                }}>
                {updateDate.map((item) => (
                    <div key={item.refreshed_at} style={{fontSize: 12, paddingBottom: 5}}>
                        {`${item.source} => Última actualización: ${item.refreshed_at}`}
                    </div>
                ))}
            </div>
            <List
                style={{paddingTop: 20}}
                {...props}
                title={'Movimientos bancarios'}
                bulkActionButtons={isAccess('delete_bank_movements') ? <BulkDeleteButton {...props} /> : false}
                exporter={isAccess('download_bank_movements') ? () => {} : false}
                sort={{field: 'id', order: 'DESC'}}
                debounce={1000}
                filters={
                    <Filter>
                        <SelectInput
                            label={'Origen'}
                            source={'source_bank_movements'}
                            choices={[
                                { id: 'stp', name: 'STP' },
                                { id: 'floyd', name: 'Floyd' },
                                { id: 'bind', name: 'Bind' },
                                { id: 'bci', name: 'BCI' },
                                { id: 'pacagel', name: 'Pagacel' },
                            ]}
                            helperText={"Fuente de operación"}
                        />
                        <SelectInput
                            label={'Cuenta bancaria'}
                            source={'source_name_bank_movements'}
                            choices={[
                                { id: 'floyd', name: 'Floyd Santander' },
                                { id: 'floyd_heroes', name: 'Floyd Heroes' },
                                { id: 'bind', name: 'Bind, Banco Industrial' },
                                { id: 'bci', name: 'BCI, Banco de Credito e Inversiones' },
                            ]}
                            helperText={"Cuenta bancaria"}
                        />
                    </Filter>
                }
            >
                <Datagrid>
                    <TextField
                        source={'id'}
                        label={'Id'}
                    />
                    <TextField
                        source={'source_name'}
                        label={'Nombre de la conexión'}
                    />
                    <TextField
                        source={'source'}
                        label={'Origen'}
                    />
                    <FunctionField
                        label={'Fecha de origen'}
                        render={(record) => {
                            return moment.utc(record.transaction_date ? record.transaction_date : record.post_date).format('LL');
                        }}
                    />
                    <FunctionField
                        label={'Fecha de lectura'}
                        render={(record) => {
                            return moment.utc(record.created_at).format('LL');
                        }}
                    />
                    <FunctionField
                        label={'Estado'}
                        render={(record) => {
                            return record.status ? record.status === 'assigned' ? 'Asignado' :
                                record.status === 'unassigned' ? 'Sin asignación' :
                                    'Eliminado' : ''
                        }}
                    />
                    <TextField
                        source={'sender_holder_id'}
                        label={'Documento del emisor'}
                    />
                    <TextField
                        source={'sender_holder_name'}
                        label={'Nombre del emisor'}
                    />
                    <TextField
                        source={'amount'}
                        label={'Monto'}
                    />
                    <TextField
                        source={'total'}
                        label={'Total'}
                    />
                    <FunctionField
                        label={'Tipo de comisión'}
                        render={(record) => {
                            return record.fee_type === 'amount' ? "Monto" : "Porcentaje"
                        }}
                    />
                    <TextField
                        source={'fee_value'}
                        label={'Comisión'}
                    />
                    <TextField
                        source={'total_fee'}
                        label={'Total de comisión'}
                    />
                    <TextField
                        source={'currency'}
                        label={'Moneda'}
                    />
                    <TextField
                        source={'description'}
                        label={'Descripción'}
                    />
                    <TextField
                        source={'external_id'}
                        label={'Id externo'}
                    />
                    <TextField
                        source={'detail_nonassignment'}
                        label={'Detalle de la NO asignación'}
                    />
                    <AssignedButton />
                </Datagrid>
            </List>
            {/* <ModalExport
                open={open}
                hiddeModal={hiddeModal}
            /> */}
        </div>
    );
};

export default BankMovementsList;