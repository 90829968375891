import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import {
  Grid,
  TextField,
  Card,
  Typography,
  CardContent,
  Button,
  MenuItem
} from '@material-ui/core';
import Swal from "sweetalert2";
import { createBrowserHistory as createHistory } from 'history';

import {
  FormTypeProvider,
  CardAccountProvider
} from '../../../Providers';

import styles from './styles';

import 'moment/locale/es';
import ReactLoading from "react-loading";

moment.locale('es');

const CardAccountEdit = (props) => {
  const history = createHistory();
  const { id } = props;
  const [formData, setFormData] = useState({});
  const [formSections, setFormSections] = useState([]);
  const [depOptions, setDepOptions] = useState({});
  const [isSpinningVisible, setIsSpinningVisible] = useState(false);
  const depRefFields = useRef({});
  const firstRender = useRef(false);
  const element = document.querySelector('[aria-label="Refresh"]');

  let dependent_fields = [];

  const formFields = useMemo(() => {
    if (!formSections.data) return [];

    const { data } = formSections;
    dependent_fields = [];

    data.forEach(section => {
      section.fields.forEach(field => {
        field.dependent_field && field.dependent_field.forEach(field_name_key => dependent_fields.push({ name: field_name_key, value: formData[field.name_key] }))
      });
    });

    const fields = data.map(section => {
      section.fields.map(field => {
        dependent_fields.forEach(async dependent_field => {
          if (field.name_key === dependent_field.name && dependent_field.value && depOptions[field.name_key]) {
            field.options = depOptions[field.name_key];
          }
        });
        return field;
      })
      return section;
    });

    return fields;
  }, [formData, formSections, depOptions]);

  const loadForm = async () => {
    try {
      const responseCA = await CardAccountProvider.getCardAccount(id);
      const { data: { data: { attributes } } } = responseCA;
      setFormData(attributes);
    } catch (error) {
      console.error("ERROR GET CARD ACCOUNT ==> ", error)
    }
  }

  useEffect(() => {
    if (element) {
      element.addEventListener('click', () => {
        loadForm();
      });
    }
  }, [element]);

  useEffect(() => {
    loadForm();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(formData).length > 0 && !firstRender.current) {
        const { user: { id: userID } } = formData;
        const sections = await FormTypeProvider.fetchFilterFormType(`?user_id=${userID}&form_type=card`);
        setFormSections(sections);
        firstRender.current = true;
      }
    })();
  }, [formData]);

  useMemo(() => {
    if (!formSections.data) return () => null;

    const { data } = formSections;

    data.forEach(section => {
      section.fields.forEach(field => {
        dependent_fields.forEach(async dependent_field => {
          if (
            field.name_key === dependent_field.name &&
            dependent_field.value &&
            depRefFields.current[dependent_field.name] !== dependent_field.value
          ) {
            const responseField = await FormTypeProvider.fetchFilterOptions(`?form_field_id=${field.id}&dependent_option=${dependent_field.value}`);
            depRefFields.current = {
              ...depRefFields.current,
              [dependent_field.name]: dependent_field.value
            };
            setDepOptions({
              ...depOptions,
              [field.name_key]: responseField.data
            })
          }
        });
      })
    });
  }, [formSections, formData]);

  const validateTypeField = field => field.type === 'text' || field.type === 'email' || field.type === 'numeric' || field.type === 'date';

  const changeValue = (value, name) => {
    const attr = {
      ...formData,
      [name]: value
    }

    setFormData(attr);
  }

  const changeOption = (value, fieldSelected) => {
    if (formData[fieldSelected.name_key] !== value) {
      const label = fieldSelected.options.find(option => option.value === value).label;
      const attr = {
        ...formData,
        [fieldSelected.name_key]: value,
        [fieldSelected.name]: label
      }

      setFormData(attr);
    }
  }

  const updateAccount = async () => {
    setIsSpinningVisible(true);
    try {
      await CardAccountProvider.updateAccount(id, formData);

      Swal.fire({
        title: "Muy bien!",
        text: "Cuenta actualizada con éxito",
        icon: "success",
        background: "rgb(19,19,19)",
        confirmButtonText: 'Ok',
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/card_accounts`);
          history.go();
        }
      });
      setIsSpinningVisible(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.data.error.message ? error.data.error.message : 'Ocurrio un error',
        icon: "warning",
        background: "rgb(19,19,19)",
        confirmButtonText: 'Ok',
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/card_accounts`);
          history.go();
        }
      });
      setIsSpinningVisible(false);
    }
  }

  const validateShowField = field => {
    if (field.hasOwnProperty('visible'))
      return formData[field.visible.key] === field.visible.value;
    else
      return true
  }

  return (
    <Card style={styles.card}>
      {formFields.length > 0 ?
        (formFields.map(section => (
          <CardContent key={section.section.id}>
            <Typography
              variant="body1"
              gutterBottom
              style={styles.titleWhite}
              key={section.section.id}
            >
              {section.section.label}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={styles.divisorTop}
            >
              {section.fields.map(field => (
                (validateShowField(field) ?
                  (validateTypeField(field) ?
                    (<Grid
                      style={styles.divisor}
                      item
                      xs={12}
                      sm={5}
                      key={field.id}
                    >
                      <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        value={formData[field.name_key]}
                        fullWidth
                        InputProps={{
                          readOnly: field.disable.admin_status,
                        }}
                        onChange={(e) => changeValue(e.target.value, field.name_key)}
                      />
                    </Grid>)
                    :
                    (<Grid
                      style={styles.divisor}
                      item
                      xs={12}
                      sm={5}
                      key={field.id}
                    >
                      <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        select
                        value={formData[field.name_key]}
                        fullWidth
                        InputProps={{
                          readOnly: field.disable.admin_status,
                        }}
                        onChange={(e) => changeOption(e.target.value, field)}
                      >
                        {field.options.map(option => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>)
                  )
                  : null)
              ))}
            </Grid>
          </CardContent>
        ))) : null
      }
      {
        isSpinningVisible ?
          (<Grid
            style={styles.spinning}
            item
            xs={12}
            sm={5}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color="#fff"
              height={'10%'}
              width={'10%'}
            />
          </Grid>)
          : (
            <Grid item xs={12} style={{ marginLeft: '46%' }}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={(e) => updateAccount()}
              >
                Actualizar
              </Button>
            </Grid>
          )
      }
    </Card>
  )
};

export default CardAccountEdit;
