import React, { useState, useEffect, useMemo, useRef } from "react";
import { Container, Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import {
  SelectInput,
  TextInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
} from "react-admin";

import { CountriesProvider } from "../../../../../Providers";
import { DOCUMENT_TYPE } from "../../../../../Utils";
import useAccessRole from "../../../../../hooks/useAccessRole";

import styles from "../../styles";

const BusinessUserEditPanel = (props) => {
  const {
    isFirstRender,
  } = props;
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const isCountryLoad = useRef();
  const element = document.querySelector('[aria-label="Refresh"]');
  const { isAccess } = useAccessRole();

  const resolveData = (data) => {
    let exist = true;
    const a = [];
    for (let i = 0; i < 100 && exist; i++) {
      a.push(data[i]);
      exist = data.hasOwnProperty(i + 1);
    }
    return a.join('');
  };

  const countryConfig = useMemo(() => {
    if (props.country !== -1) {
      const data = countries.find(val => {
        return val.id === props.country;
      })

      if (data) {
        return data;
      }
    }

    return false;
  }, [props.country, countries]);

  const changeCountry = async (data, clear_cities) => {
    try {
      const resp = await CountriesProvider.fetchProvinces(resolveData(data));
      if (clear_cities) {
        setCities([]);
      }

      setProvinces(
        resp.data.map(({ id, name }) => {
          return {
            id,
            name,
          };
        })
      );
    } catch { }
  };

  const changeProvince = async (value) => {
    try {
      const resp = await CountriesProvider.fetchCities(value);
      setCities(
        resp.data.map(({ id, name }) => {
          return {
            id,
            name,
          };
        })
      );
    } catch { }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CountriesProvider.fetchCountries();
        setCountries(
          response.data.data.map(({
            id,
            attributes: {
              name,
              is_vita_card,
              is_service_payment,
              iso_code,
              is_usdt,
              is_usdc,
              is_multi_currency,
            }
          }) => {
            return {
              id,
              name: iso_code === 'COCOP' ? 'Colombia 2' : name,
              is_vita_card,
              is_service_payment,
              is_usdt,
              is_usdc,
              is_multi_currency,
            };
          })
        );
      } catch { }
    })();
  }, [CountriesProvider, setCountries]);

  useEffect(() => {

    if (props.country !== -1 && typeof props.country === 'string' && countries.length > 0) {
      if (!isCountryLoad.current) {
        isCountryLoad.current = true;
        changeCountry(props.country, false);
      }

      if (isCountryLoad.current && element && !isFirstRender.current) {
        isFirstRender.current = true;
        element.click();
      }
    }
  }, [props.country, countries, element]);

  useEffect(() => {
    if (props.state !== -1) {
      changeProvince(props.state);
    }
  }, [props.state]);

  return (
    <>
      <Container style={{ width: "100%" }}>
        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={{ flexGrow: 1 }}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextInput label={"Nombre legal"} source={"attributes.first_name"} />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextInput label={"Dirección"} source={"attributes.address"} />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <AutocompleteInput
              label={"País"}
              source={"attributes.country_id"}
              choices={countries}
              optionText={"name"}
              optionValue={"id"}
              onChange={(event, value) => changeCountry(value, true)}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <AutocompleteInput
              label={"Estado"}
              source={"attributes.state_id"}
              choices={provinces}
              optionText={"name"}
              optionValue={"id"}
              onChange={(event, value) => changeProvince(value)}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <AutocompleteInput
              label={"Ciudad"}
              source={"attributes.city_obj_id"}
              choices={cities}
              optionText={"name"}
              optionValue={"id"}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label={"Tipo de documento"}
              source="attributes.document_type"
              choices={DOCUMENT_TYPE}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextInput
              label={"Número de documento"}
              source={"attributes.document_number"}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label={"Tipo de documento secundario"}
              source="attributes.secondary_document_type"
              choices={
                DOCUMENT_TYPE.map(item => {return {id: `s_${item.id}`, name: item.name}})
              }
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextInput
              label={"Número de documento secundario"}
              source={"attributes.secondary_document_number"}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: 'flex',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            <DateInput
              label={
                <div style={{ whiteSpace: 'nowrap' }}>Expedición del documento</div>
              }
              source={'attributes.document_expedition_date'}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextInput
              label={"Código postal"}
              source={"attributes.zipcode"}
            />
          </Col>
        </Row>
        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={styles.divisorTop}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <BooleanInput
              label={"Declaración de origen de fondos"}
              source={"attributes.accept_funds"}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <BooleanInput
              label={"Declaración PEP"}
              source={"attributes.accept_pep"}
            />
          </Col>
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <BooleanInput
              label={"Declaración de información real"}
              source={"attributes.accept_real_info"}
            />
          </Col>
          {
            countryConfig.is_vita_card && (
              <Col
                xs={3}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <BooleanInput
                  label={"Vita Card"}
                  source={"attributes.is_user_card_available"}
                />
              </Col>
            )
          }
        </Row>
        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={styles.divisorTop}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: 'flex',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            <BooleanInput
              label={'Verificación automatica'}
              source={'attributes.automatic_verification'}
            />
          </Col>
          {countryConfig.is_usdt &&
            <Col
              xs={3}
              style={{
                ...styles.divisor,
                display: 'flex',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <BooleanInput
                label={'USDT'}
                source={'attributes.is_usdt'}
              />
            </Col>
          }
          {countryConfig.is_usdc &&
            <Col
              xs={3}
              style={{
                ...styles.divisor,
                display: 'flex',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <BooleanInput
                label={'USDC'}
                source={'attributes.is_usdc'}
              />
            </Col>
          }
          {
            countryConfig.is_service_payment &&
            <Col
              xs={3}
              style={{
                ...styles.divisor,
                display: 'flex',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <BooleanInput
                label={'Pago de servicios'}
                source={'attributes.is_service_payment'}
              />
            </Col>
          }
          {
            isAccess('config_trusted_customers') &&
            <Col
              xs={3}
              style={{
                ...styles.divisor,
                display: 'flex',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <BooleanInput
                label={'Cliente de confianza'}
                source={'attributes.is_trusted'}
              />
            </Col>
          }
        </Row>
        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={styles.divisorTop}
        >
          {
            countryConfig.is_multi_currency && (
              <Col
                xs={3}
                style={{
                  ...styles.divisor,
                  display: 'flex',
                  textAlign: 'center',
                  alignContent: 'center',
                  justifyContent: 'center'
                }}
              >
                <BooleanInput
                  label={'Multi Moneda'}
                  source={'attributes.is_multi_currency'}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = ({ form }) => {
  return {
    country: form["record-form"]
      ? form["record-form"]?.initial?.attributes?.country_id
      : -1,
    state: form["record-form"]
      ? form["record-form"]?.initial?.attributes?.state_id
      : -1,
    category: form["record-form"]
      ? form["record-form"]?.initial?.attributes?.category
      : -1,
    business_user: form["record-form"]
      ? form["record-form"]?.initial?.attributes?.business_user
      : -1
  };
};

export default connect(mapStateToProps, null)(BusinessUserEditPanel);
